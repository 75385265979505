
import React, { Component } from 'react';
import { Table, Pagination } from 'react-data-components';

const germanLabels = {
  next: 'Nächste',
  prev: 'Vorherige',
  show: 'Zeige',
  entries: 'Einträge',
  noResults: 'Keine Ergebnisse gefunden',
  noData: 'Keine Daten verfügbar', // Hinzufügen des noData Labels
};


export default class PartialTable extends Component {

   render() {
      const {
        onFilter,
        onPageSizeChange,
        onPageNumberChange,
        onSort,
        pseudo,
        pageLengthOptions,
        columns,
        keys,
        initialData,
        buildRowOptions,
        readOnly,
        showPaging,
      } = this.props;

      // Protect against unloaded data.
      if (!this.props.data) {
        return null;
      }

      const {
        page,
        pageSize,
        pageNumber,
        totalPages,
        sortBy,
        initialPageLength,
        filterValues,
      } = this.props.data;

      return (
        <div className="container">
          <div className="row">
            <div className="col-xs-4">
              { showPaging && <div>
                <label htmlFor="page-menu">Page king size:</label>
                <select
                  id="page-menu"
                  value={initialPageLength}
                  onChange={onPageSizeChange}
                >
                  {pageLengthOptions.map(opt =>
                    <option key={opt} value={opt}>
                      {opt === 0 ? 'All' : opt}
                    </option>,
                  )}
                </select>
              </div>}
            </div>
            { !readOnly && <div className="col-xs-8">
              <Pagination
                className="pagination pull-right"
                currentPage={pageNumber}
                totalPages={totalPages}
                onChangePage={onPageNumberChange}
              />
            </div> }
          </div>
          <Table
            className="table table-bordered"
            dataArray={pseudo ? initialData.sort((a, b) => (a.Name || a.Herstellername).localeCompare((b.Name || b.Herstellername))) : page}
            columns={columns}
            labels={germanLabels}
            keys={keys}
            buildRowOptions={buildRowOptions}
            onSort={onSort}
            pageSize={10}
            initialPageLength={10}
          />
        </div>
      );
    }
  }