import React, { useEffect, useState, useContext } from "react";
import { Divider, List, TreeSelect, Typography, Button,Calendar, Select, Card, Col, Form, Checkbox, Input, message, Radio, Row, Spin, DatePicker, Flex,Upload, Rate, Switch } from "antd";
import MapPicker from "./MapPicker";
import dayjs from 'dayjs';
import { PicturesWall } from "./PicturesWall";
import { PlusOutlined, CheckSquareOutlined,MinusCircleOutlined , FrownOutlined, SafetyCertificateOutlined, MehOutlined, SmileOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons';

import localObject from '../germanLocale.json';
import { API, API_BASE, STRAPI_API } from "../constant";
import { getToken } from "../helpers";
import { AuthContext } from "../context/AuthContext";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Space } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import { useAuthContext } from "../context/AuthContext";
import moment from 'moment';

const { Option } = Select;
// Global style for antd
const GlobalStyle = createGlobalStyle`
  @import '~antd/dist/antd.css';
`;

const { RangePicker } = DatePicker;
const onChange = (value, dateString) => {
  console.log('Selected Time: ', value);
  console.log('Formatted Selected Time: ', dateString);
};

const onChangeDP =  (date, dateString) => {
 console.log(date, dateString);
};

const onOk = (value) => {
  console.log('onOk: ', value);
};

//const dateFormat = 'YYYY-MM-DD HH:mm:ss';
const dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

const normFile = (e) => {
  console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return (e || {}).fileList;
};

const ReferentProfile = ({ type, id, referentenData }) => {

  const { setUser, user, availableSubs, sub } = useAuthContext();
  const selectedSubTitle = (sub ? ((availableSubs || []).find((availableSub) => (availableSub || {}).stripePlanId === ((sub || {}).plan || {}).id) || {}).title : false || "");


const veranstaltung = referentenData.find((veranstaltung) => veranstaltung.id == id) || {};
const [fileList, setFileList] = useState(((veranstaltung || {}).Medien || {}).data || []);
  const [loading, setLoading] = useState(false);
  const [defaultValues, setDefaultValues] = useState({});
  const [checkedValues, setCheckedValues] = useState([]);
  const [selectedCoordinates, setSelectedCoordinates] = useState("");
  const [teilnehmerzahl, setTeilnehmerzahl] = useState('');
  const [einsatzgebiet, setEinsatzgebiet] = useState('');
  const [honorar, setHonorar] = useState('');
  const [honorarH, setHonorarH] = useState('');
  const [honorarG, setHonorarG] = useState('');
  const [placement, SetPlacement] = useState('');

  const [form] = Form.useForm();

    useEffect(() => {
        const currentValues = form.getFieldsValue();
        // Check if form values are empty
        const isFormEmpty = Object.values(currentValues).every(value => value === undefined || value === null || value === "");

        if (isFormEmpty) {
            form.setFieldsValue(defaultValues)
        }
    }, [form, defaultValues])

  useEffect(() => {
    const referent = referentenData.find((referent) => referent.id == id) || {};

        if (referent.Einsatzgebiet) setSelectedCoordinates(referent.Einsatzort[0] + "," + referent.Einsatzort[1]);

        const defaultValues = {};
        Object.keys(referent).forEach((key) => {
            defaultValues[key] = referent[key];
        })



        if (defaultValues.Terminverfuegbarkeit) defaultValues.Terminverfuegbarkeit = defaultValues.Terminverfuegbarkeit.map((t) => dayjs(t));


        setDefaultValues({
            ...defaultValues, // veranstaltung.date || 0// new Date(veranstaltung.date).toISOString().split('T')[0],
         });

    }, [referentenData]);

    const updateMarkerBasedOnStadt = () => {
    // get values of inputs Stadt and PLZ
    const stadt = form.getFieldValue("Stadt");
    const plz = form.getFieldValue("PLZ");

    fetch(`https://geocode.maps.co/search?q=${plz},${stadt},Germany&api_key=65d4b23015fec727948775azl6467b9`)
        .then((response) => response.json())
        .then((data) => {
        // update the marker based on the response
        if (((data || {})[0] || {}).lat) {
            setSelectedCoordinates(data[0].lat + "," + data[0].lon);
            message.success("Koordinaten gefunden und Karte angepasst!");
        } else {
            message.error("Keine Koordinaten gefunden...");
        }
        });

    }


  const handleSelectCoordinates = (coordinates) => {
    setSelectedCoordinates(coordinates);
  };

  const handleReferentUpdate = async (data) => {
    setLoading(true);
    try {
      const referent = referentenData.find((referent) => referent.id == id);
      let response;

        console.debug("daddta user", user);

        const dataToPost = Object.fromEntries(
                               Object.entries(data).filter(([key, value]) => value !== undefined)
                             );

         if (!dataToPost["Bild"] || (referent && (dataToPost["Bild"] || {}).data)) delete dataToPost["Bild"];
                 dataToPost.Medien = (fileList || []).map( media => {
                             if (((media.response) || []) [0]) {
                                 return media.response[0];
                             } else {
                                 return media;
                             }
                          });

         if ((((dataToPost.dragger || {})[0] || {}).response || {})[0]) dataToPost["Bild"]  = dataToPost.dragger[0].response[0];


         dataToPost.createdby = (user || {}).id;

         delete dataToPost.dragger;
//         delete dataToPost.Vergabestelle;
//          if (Object.keys(dataToPost.Vergabestelle).length === 0 || typeof ((dataToPost || {}).Vergabestelle || {}).Punkte == "undefined") delete dataToPost.Vergabestelle;

        dataToPost.Einsatzort =  JSON.parse("[" + selectedCoordinates + "]");

      if (referent) {
        response = await fetch(`${API}/referents/${referent.id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify({data: dataToPost}),
          });
      } else {
        response = await fetch(`${API}/referents`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify({data: dataToPost}),
          });
      }
      const responseData = await response.json();

      if (responseData.error) {
              alert("Es konnte nicht gespeichert werden, bitte schicken Sie unserem Support die genauen Daten die gespeichert werden sollten um das Problem zu lösen oder verändern Sie die Eingabe.");
            } else {
              // Update logic based on your application's requirements
              message.success("Daten erfolgreich gespeichert!");
              window.location.replace("/admin/meine-inserate");
            }
    } catch (error) {
      console.error(error);
      message.error("Es ist etwas schief gelaufen...");
    } finally {
      setLoading(false);
    }
  };

  const placementChange = (e: RadioChangeEvent) => {
    SetPlacement(e.target.value);
  };

  const teilnehmerzahlChange = (e: RadioChangeEvent) => {
    setTeilnehmerzahl(e.target.value);
  };
  const referent = referentenData.find((referent) => referent.id == id) || {};

const seminarOptions = [
                               "Basiskurs Wundexperte ICW",
                               "Fachtherapeut Wunde ICW",
                               "Inhouse Schulungen",
                               "Modul 1 FT",
                               "Pflegetherapeut Wunde ICW",
                               "Rezertifizierungskurs",
                               "Speaker bei Kongressen (großes Plenum)"].map((s) => { return {value: s, label:s}});

  return (
    <>
      <Card className="referent_profile_card">
        <Form
          form={form}
          layout="vertical"
          initialValues={defaultValues}
          onFinish={handleReferentUpdate}
        >
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item
                label="Name"
                name="Name"
                rules={[
                  {
                    required: true,
                    message: "Name ist ein Pflichtfeld!",
                    type: "string",
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Vorname"
                name="Vorname"
                rules={[
                  {
                    required: true,
                    message: "Vorname ist ein Pflichtfeld!",
                    type: "string",
                  },
                ]}
              >
                <Input placeholder="Vorname" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                label="Titel"
                name="Titel"
                rules={[
                ]}
              >
                <Input placeholder="Titel" />
              </Form.Item>
            </Col>
            {(((referent.Bild || {}).data || {}).attributes) && <Col span={12}><img className={"leafImage leafImage--referent"} src={API_BASE + referent.Bild.data.attributes.url}  /> </Col> }
                    <Col span={(((referent.Bild || {}).data || {}).attributes) ? 8 : 24}>
                       <Form.Item label="Bild hochladen" name="Bild">
                            <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                              <Upload.Dragger name="files"
                              crossOrigin={"use-credentials"}
                              data={{
                                ref: "api::referent.referent",
//                                refId: "42142444",
                                field: "Bild",
                              }} action={`${API}/upload`} headers={{ "Authorization": `Bearer ${getToken()}`}}>
                                <p className="ant-upload-drag-icon">
                                  <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Klicken Sie hier oder ziehen Sie das Bild oder Video in diesen Bereich</p>
                                <p className="ant-upload-hint">Bilder im Format .png, .jpeg, .jpg werden unterstützt - Videos im Format .mp4 oder .webm</p>
                              </Upload.Dragger>
                            </Form.Item>
                          </Form.Item>
                      </Col>
                      <Divider />
                                            <Form.Item label="Medien" name="Medien (Erlaubt sind sowohl PDF, als auch Bild-, Video- und Audioformate)">
                                                <PicturesWall fileList={fileList} setFileList={setFileList} />
                                              </Form.Item>
            { (selectedSubTitle === "Referent: Premium" || selectedSubTitle === "Referent: Premium Plus") && <Col span={22}>
              <Form.Item
                label="Grundqualifikation"
                name="Grundqualifikation"
                rules={[
                ]}
              >
              <Checkbox.Group options={
              ['Apotheker',
               'Ärzte (Humanmedizin)',
               'Heilpraktiker',
               'Heilerziehungspfleger',
               'Medizinische Fachangestellte (MFA)',
               'Operationstechnische Assistenten (OTA)',
               'Gesundheits- und Krankenpfleger',
               'Gesundheits- und Kinderkrankenpfleger',
               'Altenpfleger',
               'Podologen',
               'Physiotherapeuten mit Zusatzqualifikation Lymphtherapeut']} defaultValue={[]} onChange={onChange} />
              </Form.Item>
            </Col> }
            { (selectedSubTitle === "Referent: Premium" || selectedSubTitle === "Referent: Premium Plus") && <Col span={22}>
              <Form.Item
                label="Zusatzqualifikation"
                name="Zusatzqualifikation"
                rules={[
                ]}
              >
              <Checkbox.Group options={
              ['Wundexperte ICW ®',
               'Ärztlicher Wundexperte ICW ®',
               'Fachtherapeut Wunde ICW ®',
               'Pflegetherapeut ICW ®',
               'WaCert ®',
               'WTCert ®']} defaultValue={[]} onChange={onChange} />
              </Form.Item>
            </Col>}

            { (selectedSubTitle === "Referent: Premium" || selectedSubTitle === "Referent: Premium Plus") && <Col span={22}>
              <Form.Item
                label="Fortbildungsinhalte Wundexperte ICW ®"
                name="FortbildungsinhalteWundexperteICW"
                rules={[
                ]}
              >
              <Select
                    mode="multiple"
                    allowClear
                    style={{
                      width: '100%',
                    }}
                    placeholder="Fortbildungsinhalte Wundexperte ICW ®"
                    options={[
                               { "label": "ICW und Kurskonzept (Wundexperte ICW ®)", "value": "ICW und Kurskonzept (Wundexperte ICW ®)" },
                               { "label": "Haut/Hautpflege (Wundexperte ICW ®)", "value": "Haut/Hautpflege (Wundexperte ICW ®)" },
                               { "label": "Wundarten und Wundheilung (Wundexperte ICW ®)", "value": "Wundarten und Wundheilung (Wundexperte ICW ®)" },
                               { "label": "Schmerz (Wundexperte ICW ®)", "value": "Schmerz (Wundexperte ICW ®)" },
                               { "label": "Hospitation und Leistungsnachweise (Wundexperte ICW ®)", "value": "Hospitation und Leistungsnachweise (Wundexperte ICW ®)" },
                               { "label": "Hygiene in der Wundversorgung (Wundexperte ICW ®)", "value": "Hygiene in der Wundversorgung (Wundexperte ICW ®)" },
                               { "label": "Wundbeurteilung und Wunddokumentation (Wundexperte ICW ®)", "value": "Wundbeurteilung und Wunddokumentation (Wundexperte ICW ®)" },
                               { "label": "Expertenstandard Pflege von Menschen mit Chronischen Wunden (DNQP) (Wundexperte ICW ®)", "value": "Expertenstandard Pflege von Menschen mit Chronischen Wunden (DNQP) (Wundexperte ICW ®)" },
                               { "label": "Ulcus cruris Prophylaxe/Behandlung (Wundexperte ICW ®)", "value": "Ulcus cruris Prophylaxe/Behandlung (Wundexperte ICW ®)" },
                               { "label": "Dekubitus/Prophylaxe und Therapie (Wundexperte ICW ®)", "value": "Dekubitus/Prophylaxe und Therapie (Wundexperte ICW ®)" },
                               { "label": "Diabetisches Fußsyndrom (DFS)/Prophylaxe und Therapie (Wundexperte ICW ®)", "value": "Diabetisches Fußsyndrom (DFS)/Prophylaxe und Therapie (Wundexperte ICW ®)" },
                               { "label": "Grundsätze Wundversorgung und Wundauflagen (Wundexperte ICW ®)", "value": "Grundsätze Wundversorgung und Wundauflagen (Wundexperte ICW ®)" },
                               { "label": "Wundreinigung/Wundspülung (Wundexperte ICW ®)", "value": "Wundreinigung/Wundspülung (Wundexperte ICW ®)" },
                               { "label": "Infektmanagement (Wundexperte ICW ®)", "value": "Infektmanagement (Wundexperte ICW ®)" },
                               { "label": "Fallmanagement (Wundexperte ICW ®)", "value": "Fallmanagement (Wundexperte ICW ®)" },
                               { "label": "Finanzierung (Wundexperte ICW ®)", "value": "Finanzierung (Wundexperte ICW ®)" },
                               { "label": "Rechtliche Aspekte der Wundversorgung Edukation (Wundexperte ICW ®)", "value": "Rechtliche Aspekte der Wundversorgung Edukation (Wundexperte ICW ®)" },
                               { "label": "Ernährung (Wundexperte ICW ®)", "value": "Ernährung (Wundexperte ICW ®)" },
                               { "label": "Vertiefungsstunde (Wundexperte ICW ®)", "value": "Vertiefungsstunde (Wundexperte ICW ®)" }
                             ]
}
                  />
              </Form.Item>
            </Col>}


            { (selectedSubTitle === "Referent: Premium" || selectedSubTitle === "Referent: Premium Plus") && <Col span={22}>
              <Form.Item
                label="Fortbildungsinhalte Fachtherapeut Wunde ICW ®"
                name="FortbildungsinhalteFachtherapeutWundeICW"
                rules={[
                ]}
              >
    <TreeSelect
      showSearch
      style={{ width: '100%' }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      placeholder="Fortbildungsinhalte Fachtherapeut Wunde ICW ®"
      allowClear
      multiple
      treeDefaultExpandAll
      onChange={onChange}
      treeData={[
                  {
                    "value": "KURSEINFÜHRUNG (Fachtherapeut Wunde ICW ®)",
                    "title": "KURSEINFÜHRUNG (Fachtherapeut Wunde ICW ®)",
                    "children": []
                  },
                  {
                    "value": "CHRONISCHE WUNDEN PATHOPHYSIOLOGIE UND Diagnostik (Fachtherapeut Wunde ICW ®)",
                    "title": "CHRONISCHE WUNDEN PATHOPHYSIOLOGIE UND Diagnostik (Fachtherapeut Wunde ICW ®)",
                    "children": []
                  },
                  {
                    "value": "WUNDASSESSMENT UND WUNDDOKUMENTATION (Fachtherapeut Wunde ICW ®)",
                    "title": "WUNDASSESSMENT UND WUNDDOKUMENTATION (Fachtherapeut Wunde ICW ®)",
                    "children": []
                  },
                  {
                    "value": "LYMPHATISCHE ERKRANKUNGEN (Fachtherapeut Wunde ICW ®)",
                    "title": "LYMPHATISCHE ERKRANKUNGEN (Fachtherapeut Wunde ICW ®)",
                    "children": []
                  },
                  {
                    "value": "KOMPLEXE WUNDSITUATIONEN/WUNDREINIGUNG/HAUTSCHÄDEN (Fachtherapeut Wunde ICW ®)",
                    "title": "KOMPLEXE WUNDSITUATIONEN/WUNDREINIGUNG/HAUTSCHÄDEN (Fachtherapeut Wunde ICW ®)",
                    "children": []
                  },
                  {
                    "value": "TUMORWUNDEN/ PALLIATIVE VERSORGUNG (Fachtherapeut Wunde ICW ®)",
                    "title": "TUMORWUNDEN/ PALLIATIVE VERSORGUNG (Fachtherapeut Wunde ICW ®)",
                    "children": []
                  },
                  {
                    "value": "SCHMERZ (Fachtherapeut Wunde ICW ®)",
                    "title": "SCHMERZ (Fachtherapeut Wunde ICW ®)",
                    "children": []
                  },
                  {
                    "value": "GESUNDHEITSÖKONOMIE (Fachtherapeut Wunde ICW ®)",
                    "title": "GESUNDHEITSÖKONOMIE (Fachtherapeut Wunde ICW ®)",
                    "children": []
                  },
                  {
                    "value": "HYGIENE (Fachtherapeut Wunde ICW ®)",
                    "title": "HYGIENE (Fachtherapeut Wunde ICW ®)",
                    "children": []
                  },
                  {
                    "value": "ULCUS CRURIS (Fachtherapeut Wunde ICW ®)",
                    "title": "ULCUS CRURIS (Fachtherapeut Wunde ICW ®)",
                    "children": []
                  },
                  {
                    "value": "Grundlagen Gefäßsystem, CVI und Differentialdiagnosen",
                    "title": "Grundlagen Gefäßsystem, CVI und Differentialdiagnosen",
                    "children": [
                      {
                        "value": "a) Interventionen bei venösem Ulcus und Lokaltherapie",
                        "title": "a) Interventionen bei venösem Ulcus und Lokaltherapie",
                        "children": []
                      },
                      {
                        "value": "b) Ulcus cruris arteriosum/mixtum",
                        "title": "b) Ulcus cruris arteriosum/mixtum",
                        "children": []
                      },
                      {
                        "value": "c) Amputation",
                        "title": "c) Amputation",
                        "children": []
                      },
                      {
                        "value": "d) Kompression und Kommunikation",
                        "title": "d) Kompression und Kommunikation",
                        "children": []
                      }
                    ]
                  },
                  {
                    "value": "DEKUBITUS (Fachtherapeut Wunde ICW ®)",
                    "title": "DEKUBITUS (Fachtherapeut Wunde ICW ®)",
                    "children": [
                      {
                        "value": "a) Grundlagen und Differentialdiagnostik",
                        "title": "a) Grundlagen und Differentialdiagnostik",
                        "children": []
                      },
                      {
                        "value": "b) Hilfsmittel und Kommunikation",
                        "title": "b) Hilfsmittel und Kommunikation",
                        "children": []
                      },
                      {
                        "value": "c) Therapie und Schwerpunkte Lokaltherapie",
                        "title": "c) Therapie und Schwerpunkte Lokaltherapie",
                        "children": []
                      }
                    ]
                  },
                  {
                    "value": "DIABETISCHES FUßSYNDROM (Fachtherapeut Wunde ICW ®)",
                    "title": "DIABETISCHES FUßSYNDROM (Fachtherapeut Wunde ICW ®)",
                    "children": [
                      {
                        "value": "a) Grundlagen und Diagnostik",
                        "title": "a) Grundlagen und Diagnostik",
                        "children": []
                      },
                      {
                        "value": "b) Unterstützende Maßnahmen und Kommunikation",
                        "title": "b) Unterstützende Maßnahmen und Kommunikation",
                        "children": []
                      },
                      {
                        "value": "c) Infektionsmanagement und lokale Wundtherapie",
                        "title": "c) Infektionsmanagement und lokale Wundtherapie",
                        "children": []
                      }
                    ]
                  },
                  {
                    "value": "THERMISCH BEDINGTE WUNDEN/ NARBEN (Fachtherapeut Wunde ICW ®)",
                    "title": "THERMISCH BEDINGTE WUNDEN/ NARBEN (Fachtherapeut Wunde ICW ®)",
                    "children": [
                      {
                        "value": "a) Thermisch bedingte Wunden",
                        "title": "a) Thermisch bedingte Wunden",
                        "children": []
                      },
                      {
                        "value": "b) Narben",
                        "title": "b) Narben",
                        "children": []
                      }
                    ]
                  },
                  {
                    "value": "DERMATOLOGISCHE WUNDEN (Fachtherapeut Wunde ICW ®)",
                    "title": "DERMATOLOGISCHE WUNDEN (Fachtherapeut Wunde ICW ®)",
                    "children": []
                  },
                  {
                    "value": "PRÜFUNGSVORBEREITUNG (Fachtherapeut Wunde ICW ®)",
                    "title": "PRÜFUNGSVORBEREITUNG (Fachtherapeut Wunde ICW ®)",
                    "children": []
                  },
                  {
                    "value": "ORGANISATION WUNDMANAGEMENT (Fachtherapeut Wunde ICW ®)",
                    "title": "ORGANISATION WUNDMANAGEMENT (Fachtherapeut Wunde ICW ®)",
                    "children": []
                  },
                  {
                    "value": "LEISTUNGSNACHWEISE (Fachtherapeut Wunde ICW ®)",
                    "title": "LEISTUNGSNACHWEISE (Fachtherapeut Wunde ICW ®)",
                    "children": []
                  }
                ]
}
    />
              </Form.Item>
            </Col> }

            { (selectedSubTitle === "Referent: Premium" || selectedSubTitle === "Referent: Premium Plus") && <Col span={22}>
                          <Form.Item
                            label="Fortbildungsinhalte Ärztlicher Wundexperte ICW ®"
                            name="FortbildungsinhalteAerztlicherWundexperteICW"
                            rules={[
                            ]}
                          >
                          <Select
                                mode="multiple"
                                allowClear
                                style={{
                                  width: '100%',
                                }}
                                placeholder="Fortbildungsinhalte Ärztlicher Wundexperte ICW ®"
                                options={
                                [
                                  {
                                    "label": "ICW UND KURSKONZEPT SOWIE EINFÜHRUNG UND KENNENLERNEN (Ärztlicher Wundexperte ICW ®)",
                                    "value": "ICW UND KURSKONZEPT SOWIE EINFÜHRUNG UND KENNENLERNEN (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "PHYSIOLOGIE UND PATHOPHYSIOLOGIE DER WUNDHEILUNG (Ärztlicher Wundexperte ICW ®)",
                                    "value": "PHYSIOLOGIE UND PATHOPHYSIOLOGIE DER WUNDHEILUNG (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "WUNDBEURTEILUNG UND -DOKUMENTATION (Ärztlicher Wundexperte ICW ®)",
                                    "value": "WUNDBEURTEILUNG UND -DOKUMENTATION (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "WUNDTHERAPEUTIKA – Systematik (Ärztlicher Wundexperte ICW ®)",
                                    "value": "WUNDTHERAPEUTIKA – Systematik (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "LOKALTHERAPIE CHRONISCHER WUNDEN - SYSTEMATIK (Ärztlicher Wundexperte ICW ®)",
                                    "value": "LOKALTHERAPIE CHRONISCHER WUNDEN - SYSTEMATIK (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "EXSUDAT- UND GERUCHSMANAGEMENT SOWIE WUNDRANDSCHUTZ (Ärztlicher Wundexperte ICW ®)",
                                    "value": "EXSUDAT- UND GERUCHSMANAGEMENT SOWIE WUNDRANDSCHUTZ (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "DÉBRIDEMENT – SYSTEMATIK (Ärztlicher Wundexperte ICW ®)",
                                    "value": "DÉBRIDEMENT – SYSTEMATIK (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "PHYSIKALISCHE VERFAHREN IN DER WUNDBEHANDLUNG - SYSTEMATIK (Ärztlicher Wundexperte ICW ®)",
                                    "value": "PHYSIKALISCHE VERFAHREN IN DER WUNDBEHANDLUNG - SYSTEMATIK (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "SPEZIELLE WUNDTHERAPEUTIKA (Ärztlicher Wundexperte ICW ®)",
                                    "value": "SPEZIELLE WUNDTHERAPEUTIKA (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "EXTERNA INKLUSIVE HAUTPFLEGE (Ärztlicher Wundexperte ICW ®)",
                                    "value": "EXTERNA INKLUSIVE HAUTPFLEGE (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "PAVK KRANKHEITSBILD UND DIAGNOSTIK ® ABI-MESSUNG (Ärztlicher Wundexperte ICW ®)",
                                    "value": "PAVK KRANKHEITSBILD UND DIAGNOSTIK ® ABI-MESSUNG (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "PAVK THERAPIE (Ärztlicher Wundexperte ICW ®)",
                                    "value": "PAVK THERAPIE (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "CVI KRANKHEITSBILD UND DIAGNOSTIK (Ärztlicher Wundexperte ICW ®)",
                                    "value": "CVI KRANKHEITSBILD UND DIAGNOSTIK (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "CVI THERAPIE (Ärztlicher Wundexperte ICW ®)",
                                    "value": "CVI THERAPIE (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "KOMPRESSION - SYSTEMATIK UND (Ärztlicher Wundexperte ICW ®)",
                                    "value": "KOMPRESSION - SYSTEMATIK UND (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "ÖDEME UND DEREN DIFFERENZIALDIAGNOSE (Ärztlicher Wundexperte ICW ®)",
                                    "value": "ÖDEME UND DEREN DIFFERENZIALDIAGNOSE (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "LYMPHÖDEM PRIMÄR UND SEKUNDÄR - DIAGNOSTIK UND THERAPIEANSÄTZE (Ärztlicher Wundexperte ICW ®)",
                                    "value": "LYMPHÖDEM PRIMÄR UND SEKUNDÄR - DIAGNOSTIK UND THERAPIEANSÄTZE (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "HYGIENE BEI CHRONISCHEN WUNDEN (Ärztlicher Wundexperte ICW ®)",
                                    "value": "HYGIENE BEI CHRONISCHEN WUNDEN (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "INFEKTIONSBEKÄMPFUNG – (Ärztlicher Wundexperte ICW ®)",
                                    "value": "INFEKTIONSBEKÄMPFUNG – (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "DIABETISCHES FUßSYNDROM GRUNDLAGEN UND DIAGNOSTIK (Ärztlicher Wundexperte ICW ®)",
                                    "value": "DIABETISCHES FUßSYNDROM GRUNDLAGEN UND DIAGNOSTIK (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "DIABETISCHES FUßSYNDROM - THERAPIE INKLUSIVE ENTLASTUNGSVERFAHREN (Ärztlicher Wundexperte ICW ®)",
                                    "value": "DIABETISCHES FUßSYNDROM - THERAPIE INKLUSIVE ENTLASTUNGSVERFAHREN (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "ÜBERLEITUNGSMANAGEMENT UND INTERPROFESSIONELLES NETZWERK HEIL- UND HILFSMITTEL BEI PATIENTEN MIT CHRONISCHEN WUNDEN (Ärztlicher Wundexperte ICW ®)",
                                    "value": "ÜBERLEITUNGSMANAGEMENT UND INTERPROFESSIONELLES NETZWERK HEIL- UND HILFSMITTEL BEI PATIENTEN MIT CHRONISCHEN WUNDEN (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "ÜBUNGSSEQUENZ FALLMANAGEMENT (Ärztlicher Wundexperte ICW ®)",
                                    "value": "ÜBUNGSSEQUENZ FALLMANAGEMENT (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "DEKUBITUS (Ärztlicher Wundexperte ICW ®)",
                                    "value": "DEKUBITUS (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "PLASTISCH-CHIRURGISCHE THERAPIEOPTIONEN (Ärztlicher Wundexperte ICW ®)",
                                    "value": "PLASTISCH-CHIRURGISCHE THERAPIEOPTIONEN (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "PALLIATIVE WUNDBEHANDLUNG (Ärztlicher Wundexperte ICW ®)",
                                    "value": "PALLIATIVE WUNDBEHANDLUNG (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "SCHMERZTHERAPIE (Ärztlicher Wundexperte ICW ®)",
                                    "value": "SCHMERZTHERAPIE (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "GESUNDHEITSÖKONOMIE (Ärztlicher Wundexperte ICW ®)",
                                    "value": "GESUNDHEITSÖKONOMIE (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "MEDIZINPRODUKTERECHT (Ärztlicher Wundexperte ICW ®)",
                                    "value": "MEDIZINPRODUKTERECHT (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "ERNÄHRUNG BEI PATIENTEN MIT CHRONISCHEN WUNDEN (Ärztlicher Wundexperte ICW ®)",
                                    "value": "ERNÄHRUNG BEI PATIENTEN MIT CHRONISCHEN WUNDEN (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "SELTENE URSACHEN CHRONISCHER WUNDEN (Ärztlicher Wundexperte ICW ®)",
                                    "value": "SELTENE URSACHEN CHRONISCHER WUNDEN (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "THERMISCHE URSACHEN FÜR WUNDEN (Ärztlicher Wundexperte ICW ®)",
                                    "value": "THERMISCHE URSACHEN FÜR WUNDEN (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "ÜBUNGSSEQUENZ FALLMANAGEMENT (Ärztlicher Wundexperte ICW ®)",
                                    "value": "ÜBUNGSSEQUENZ FALLMANAGEMENT (Ärztlicher Wundexperte ICW ®)"
                                  },
                                  {
                                    "label": "PRÜFUNGSVORBEREITUNG (Ärztlicher Wundexperte ICW ®)",
                                    "value": "PRÜFUNGSVORBEREITUNG (Ärztlicher Wundexperte ICW ®)"
                                  }
                                ]

            }
                              />
                          </Form.Item>
                        </Col> }

                        { (selectedSubTitle === "Referent: Premium" || selectedSubTitle === "Referent: Premium Plus") && <Col span={22}>
                                      <Form.Item
                                        label="Fortbildungsinhalte Pflegetherapeut Wunde ICW ®"
                                        name="FortbildungsinhaltePflegetherapeutWundeICW"
                                        rules={[
                                        ]}
                                      >
                                      <Select
                                            mode="multiple"
                                            allowClear
                                            style={{
                                              width: '100%',
                                            }}
                                            placeholder="Fortbildungsinhalte Pflegetherapeut Wunde ICW ®"
                                            options={[
                                                       {
                                                         "label": "SEMINAREINFÜHRUNG (Pflegetherapeut Wunde ICW ®)",
                                                         "value": "SEMINAREINFÜHRUNG (Pflegetherapeut Wunde ICW ®)"
                                                       },
                                                       {
                                                         "label": "WISSENSCHAFTLICH ORIENTIERTES ARBEITEN (THEMENBLOCK 1) (Pflegetherapeut Wunde ICW ®)",
                                                         "value": "WISSENSCHAFTLICH ORIENTIERTES ARBEITEN (THEMENBLOCK 1) (Pflegetherapeut Wunde ICW ®)"
                                                       },
                                                       {
                                                         "label": "EXPERTENSTANDARD (THEMENBLOCK 2) (Pflegetherapeut Wunde ICW ®)",
                                                         "value": "EXPERTENSTANDARD (THEMENBLOCK 2) (Pflegetherapeut Wunde ICW ®)"
                                                       },
                                                       {
                                                         "label": "LEBENSQUALITÄT, SELBSTPFLEGEMANAGEMENT UND EDUKATION (THEMENBLOCK 3) (Pflegetherapeut Wunde ICW ®)",
                                                         "value": "LEBENSQUALITÄT, SELBSTPFLEGEMANAGEMENT UND EDUKATION (THEMENBLOCK 3) (Pflegetherapeut Wunde ICW ®)"
                                                       },
                                                       {
                                                         "label": "CASEMANAGEMENT (THEMENBLOCK 4) (Pflegetherapeut Wunde ICW ®)",
                                                         "value": "CASEMANAGEMENT (THEMENBLOCK 4) (Pflegetherapeut Wunde ICW ®)"
                                                       },
                                                       {
                                                         "label": "PROJEKTMANAGEMENT (THEMENBLOCK 5) (Pflegetherapeut Wunde ICW ®)",
                                                         "value": "PROJEKTMANAGEMENT (THEMENBLOCK 5) (Pflegetherapeut Wunde ICW ®)"
                                                       },
                                                       {
                                                         "label": "GRUNDLAGEN DES SACHVERSTÄNDIGEN-GUTACHTENS (THEMENBLOCK 6) (Pflegetherapeut Wunde ICW ®)",
                                                         "value": "GRUNDLAGEN DES SACHVERSTÄNDIGEN-GUTACHTENS (THEMENBLOCK 6) (Pflegetherapeut Wunde ICW ®)"
                                                       },
                                                       {
                                                         "label": "PÄDAGOGISCHE AUFGABEN (THEMENBLOCK 7) (Pflegetherapeut Wunde ICW ®)",
                                                         "value": "PÄDAGOGISCHE AUFGABEN (THEMENBLOCK 7) (Pflegetherapeut Wunde ICW ®)"
                                                       },
                                                       {
                                                         "label": "LERNZIELÜBERPRÜFUNG (THEMENBLOCK 8) (Pflegetherapeut Wunde ICW ®)",
                                                         "value": "LERNZIELÜBERPRÜFUNG (THEMENBLOCK 8) (Pflegetherapeut Wunde ICW ®)"
                                                       }
                                                     ]
                        }
                                          />
                                      </Form.Item>
                                    </Col> }
            { (selectedSubTitle === "Referent: Premium" || selectedSubTitle === "Referent: Premium Plus") && <Col span={22}>
              <Form.Item
                label="Seminartyp"
                name="Seminartyp"
                rules={[
                ]}
              >
               <Select
                      mode="multiple"
                      allowClear
                      style={{
                        width: '100%',
                      }}
                      placeholder="Seminartyp"
                                                          options={
              seminarOptions

        }

              />
              </Form.Item>
            </Col> }

            <Col span={14}>
              <Form.Item
                label="Stadt"

                name="Stadt"
                rules={[
                  {
                    required: true,
                    message: "Stadt ist ein Pflichtfeld!",
                    type: "string",
                  },
                ]}
              >
                <Input id="Stadt" placeholder="Stadt" />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item
                label="PLZ"
                name="PLZ"
                rules={[
                ]}
              >
                <Input id="PLZ" placeholder="PLZ" />
              </Form.Item>
            </Col>
            <Col span={24}>
          <Button ghost
            type="primary"
            size="large"
            onClick={updateMarkerBasedOnStadt}
          >
            Aktualisiere den Marker basierend auf der Stadt und Postleitzahl
          </Button>
            </Col>

            <Col span={24}>
              <div className={"ant-form-item"}>
                <div className={"ant-row ant-form-item-row"}>
                  <div className={"ant-col ant-form-item-label"}>Einsatzort wählen <i>(ziehen sie den Marker auf den gewünschten Ort)</i></div>
                  <MapPicker defaultMarkerCoordinates={selectedCoordinates ? JSON.parse("[" + selectedCoordinates + "]") : [
                                               50.02254683363231,
                                               10.919726205325217
                                               ]} onSelectCoordinates={handleSelectCoordinates} />
                </div>
              </div>
            </Col>
              { (selectedSubTitle === "Referent: Premium" || selectedSubTitle === "Referent: Premium Plus") && <Col span={24}>
                    <Form.Item
                      value={teilnehmerzahl}
                      label="Einsatzgebiet (Radius in km)"
                      name="Einsatzgebiet"
                      rules={[
                      ]}
                    >
                      <Radio.Group>
                              <Radio.Button value="z0-50">0-50</Radio.Button>
                              <Radio.Button value="z51-150">51-150</Radio.Button>
                              <Radio.Button value="z151-300">151-300</Radio.Button>
                              <Radio.Button value="züber 300">über 300</Radio.Button>
                            </Radio.Group>
                    </Form.Item>
                  </Col> }
              { (selectedSubTitle === "Referent: Premium Plus") && <Col span={24}>
                            <Form.Item
                                value={honorar}
                              label="Honorarvorstellung (Pro Unterrichtseinheit in €/ UE)"
                                name="HonorarvorstellungProUnterrichtseinheit"
                                rules={[
                                ]}
                              ><Radio.Group buttonStyle="solid" optionType="button" value={honorar} onChange={onChange}>
                              <Radio.Button value="z0-50">0-50 €/ UE</Radio.Button>
                              <Radio.Button value="z51-100">51-100 €/ UE</Radio.Button>
                              <Radio.Button value="z101-150">101-150 €/ UE</Radio.Button>
                              <Radio.Button value="z150">über 150 €/ UE</Radio.Button>
                            </Radio.Group></Form.Item><Form.Item
                                  value={honorarH}
                                  label="Honorarvorstellung (Pauschale halber Tag in €)"
                                  name="HonorarvorstellungPauschaleHalberTag"
                                  rules={[
                                  ]}
                                ><Radio.Group buttonStyle="solid" optionType="button" value={honorarH} onChange={onChange}>
                              <Radio.Button value="zunter 200">unter 200€</Radio.Button>
                              <Radio.Button value="z200">200 €</Radio.Button>
                              <Radio.Button value="z300">300 €</Radio.Button>
                              <Radio.Button value="z400">400 €</Radio.Button>
                              <Radio.Button value="z500">500 €</Radio.Button>
                              <Radio.Button value="züber 500">über 500 €</Radio.Button>
                            </Radio.Group></Form.Item><Form.Item
                              value={honorarG}
                              label="Honorarvorstellung (Pauschale ganzer Tag)"
                              name="HonorarvorstellungPauschaleGanzerTag"
                              rules={[
                              ]}
                            ><Radio.Group buttonStyle="solid" optionType="button" value={honorarG} onChange={setHonorar}>
                              <Radio.Button value="zunter 400">unter 400€</Radio.Button>
                              <Radio.Button value="z500">500 €</Radio.Button>
                              <Radio.Button value="z600">600 €</Radio.Button>
                              <Radio.Button value="z700">700 €</Radio.Button>
                              <Radio.Button value="z800">800 €</Radio.Button>
                              <Radio.Button value="züber 800">über 800 €</Radio.Button>
                            </Radio.Group>
                    </Form.Item>
                  </Col> }

              { (selectedSubTitle === "Referent: Premium Plus") && <Col span={24}>
                    <Form.Item
                      value={teilnehmerzahl}
                      label="Terminverfügbarkeit"
                      name="Terminverfuegbarkeit"
                      rules={[
                      ]}
                    >
                    <DatePicker
                          multiple
                          onChange={onChangeDP}
                          maxTagCount="responsive"
                          size="large"
                        />
                    </Form.Item>
                  </Col> }

            <Col span={24}>
              <Form.Item
                label="Kurzbiografie"
                name="Bio"
                rules={[
                ]}
              >
                                        <ReactQuill placeholder="Kurze Biographie..." theme="snow" />
              </Form.Item>
            </Col>

            <Col span={11}>
              <Form.Item
                label="Externer Link"
                name="ExternerLink"
              >
                <Input placeholder="beispiel.de" addonBefore="https://" />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                label="Kontaktinformation: Email"
                name="Email"
                rules={[
                  {
                    required: true,
                    type: "email",
                  },
                ]}
              >
                <Input id="emailSignId" placeholder="Email" />
              </Form.Item>
            </Col>
        <Divider />

            <Col span={16}>
              <Form.Item
                label={<span  style={{fontSize: '20px', fontWeight: 800}}>Profil veröffentlichen</span>}
                name="Published"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            { selectedSubTitle === "Referent: Premium Plus" && <Col span={16}>
              <Form.Item
                label={<span  style={{fontSize: '20px', fontWeight: 400}}>Fokussiere Profil</span>}
                name="Fokusiere"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col> }


          </Row>
          <Button
            className="referent_save_btn"
            htmlType="submit"
            type="primary"
            size="large"
          >
            {loading ? (
              <>
                <Spin size="small" /> Speichern...
              </>
            ) : (
              "Speichern"
            )}
          </Button>
        </Form>
      </Card>
    </>
  );
};

export default ReferentProfile;